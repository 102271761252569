/* AddBanner.css */

.add-banner-container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-banner-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.add-banner-form {
  display: flex;
  flex-direction: column;
}

.add-banner-label {
  font-weight: bold;
  margin-top: 10px;
}

.add-banner-input {
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.add-banner-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.add-banner-button:hover {
  background-color: #0056b3;
}

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.image-preview {
  flex: 0 0 calc(33.33% - 10px);
  margin: 0 5px 10px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}






.banner{
  width: 100%;
  height: 100px;
  border: 2px solid green;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.iconss-fc{
  margin-left: 80px;
  cursor: pointer;
}