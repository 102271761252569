.adb1-h3 {
    margin-bottom: 1.5rem;
    font-size: 28px;
    font-family: Roboto !important;
}
.long-5{
    width: 100%;
    height: calc(3rem + 2px);
    background-color: white;
}
.adb1-btn{
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.addbrand-input{
    background-color: white;
    height: 50px;
}