:root{
    --color-212529: #212529;
    --color-ffd333: #ffd333;
    --color-47ad24: #47ad24;
    --color-e52e2e: #e52e2e;
    --color-828599: #828599;
    --color-3d464d: #3d464d;
    --color-ced4da: #ced4da;

}
.green{
    color: var(--color-47ad24);
    font-size: 14px;
    font-family: Roboto !important;
}
.red{
    color:var(--color-e52e2e);
    font-size: 14px;
    font-family: Roboto !important;
}
.form-control{
    box-shadow: none !important;
    border-color: var(--color-ced4da) !important;
}
.sub-title{
    font-size: 24px;
    font-family: Roboto !important;
    color: var(--color-212529);
}
.desc{
    color: var(--color-828599);
    font-size: 13px;
    font-family: Roboto !important;
}
.error{
    color: var(--color-e52e2e);
    font-size: 12px;
}
