.adp-h3 {
  margin-bottom: 1.5rem;
  font-size: 28px;
  font-family: Roboto !important;
}
.adp-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 0.7rem;
  margin-bottom: 3rem;
}
.long-6 {
  width: 100%;
  height: calc(3rem + 2px);
  background-color: white;
}
.long-7 {
  margin-top: 10px;
  width: 100%;
  height: calc(3rem + 2px);
  background-color: #ffffff;
}
.addProd-div-2 {
  position: relative;
}
.showimages {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
.close-button {
  position: absolute;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  width: 1.5em;
  height: 1.5em;
  top: 4px;
  right: 4px;
}

.close-button::before,
.close-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 2px;
  background-color: white;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-button::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-button:hover::before,
.close-button:hover::after {
  background-color: #aaa;
}

.close-button:focus {
  box-shadow: 0 0 0 0.25em rgba(0, 0, 0, 0.25);
}

/* Optional: Adjust the size and color of the close button */
.close-button {
  font-size: 1.5em;
  color: white;
}

.add-select {
  width: 100%;
}
.ant-select-selection-overflow{
  height: 44px;
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}

.form-addproduct{
  display: flex;
  gap : 1rem;
  flex-direction: column;
}
.addProduct-div-01{
  background-color: white;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 25px;
  cursor: pointer;
}