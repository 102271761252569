.addB-h3 {
  margin-bottom: 1.5rem;
  font-size: 28px;
  font-family: Roboto !important;
}
/* .addB-div-1 {
  display: flex;
} */
.quill-1 {
  width: 100%;
  background-color: white;
}
.long_in {
  width: 100%;
  height: calc(3rem + 2px);
  background-color: white;
}
.select-1 {
  display: block;
  width: 100%;
  height: calc(3rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}
.addB-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
/* .StepperContainer-0-2-1{
    margin-left: -109px;
    margin-bottom: 14px;
} */


:where(.css-dev-only-do-not-override-yp8pcc).ant-upload-wrapper .ant-upload-drag {
    width: 100%;
    height: 20%;
    margin-bottom: 20px;
    background-color: white;
}