/* Form floating container */
.form-floating {
  position: relative;
  margin-top: 1rem;
}

/* Form control input */
.form-control {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background-color: transparent;
}
input{
    width:100%;
    margin-bottom: 5px;
}
/* Form floating label */
.form-floating label {
  position: absolute;
  top: 0;
  left: 0.75rem;
  padding: 0 0.25rem;
  font-size: 1rem;
  color: #495057;
}

